// Area Chart
.chart-area {
  position: relative;
  height: 10rem;
  width: 100%;
  @include media-breakpoint-up(md) {
    height: 20rem;
  }
}

// Bar Chart
.chart-bar {
  position: relative;
  height: 10rem;
  width: 100%;
  @include media-breakpoint-up(md) {
    height: 20rem;
  }
}

// Pie Chart
.chart-pie {
  position: relative;
  height: 10rem;
  width: 100%;
  @include media-breakpoint-up(md) {
    height: calc(20rem - 43px) !important;
  }
}
