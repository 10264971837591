.progress-step {
    position: relative;
    display: flex;
    justify-content: center;
    line-height: 1;
    font-weight: bold;
    z-index: 2;

    $this: &;

    &:hover {
        text-decoration: none;
    }

    &:not(#{$this}--disabled):hover {
        #{$this}__counter {
            color: $cb;
            border-color: $cb;
        }
        #{$this}__label {
            color: $cb;
        }
    }

    &__counter {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        border: 2px solid $gray-400;
        color: $gray-400;
        background: #fff;
        font-size: 14px;
        font-weight: bold;
        position: relative;
    }

    &__label {
        text-align: center;
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        color: $gray-600;
        white-space: nowrap;
    }

    &--done {
        #{$this}__counter {
            background: $cb;
            color: $cb;
            border-color: $cb;

            &:before {
                width: 100%;
                text-align: center;
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: #fff;
                display: block;
                font-size: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        #{$this}__label {
            color: $cb;
        }
    }

    &--disabled {
        cursor: not-allowed;

        #{$this}__counter {
            color: $gray-300;
            border-color: $gray-300;
        }
        #{$this}__label {
            color: $gray-300;
        }
    }

    &--active {
        z-index: 1;

        #{$this}__counter {
            color: $cb;
            border-color: $cb;
        }
        #{$this}__label {
            color: $cb;
        }

        &:before {
            display: block;
            content: "";
            height: 2px;
            position: absolute;
            width: 100%;
            bottom: 16px;
            background: $cb;
            z-index: -1;
        }
    }
}

.progressbar {
    position: relative;
    max-width: 1200px;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

    &__bg {
        width: 100%;
        height: 2px;
        background: $gray-400;
        position: absolute;
        bottom: 16px;
        left: 0;

        span {
            display: block;
            width: calc(100% / 5 * var(--progress));
            background: $cb;
            height: 2px;
        }

        &.progress-1 {
            span {
                width: calc(100% / 5 * 1);
            }
        }
        &.progress-2 {
            span {
                width: calc(100% / 5 * 2);
            }
        }
        &.progress-3 {
            span {
                width: calc(100% / 5 * 3);
            }
        }
        &.progress-4 {
            span {
                width: calc(100% / 5 * 4);
            }
        }
        &.progress-5 {
            span {
                width: calc(100% / 5 * 5);
            }
        }
    }
    &__steps {
        display: flex;
        justify-content: space-between;
    }
}

.progress-sm {
    height: .5rem;
}
