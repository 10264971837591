.header {
    background: #fff;
    margin-bottom: 30px;
    box-shadow: 0 0.1px 0.2px rgba(0, 0, 0, 0.02),
        0 0.3px 0.5px rgba(0, 0, 0, 0.028), 0 0.6px 1px rgba(0, 0, 0, 0.035),
        0 1.1px 1.8px rgba(0, 0, 0, 0.042), 0 2.1px 3.3px rgba(0, 0, 0, 0.05),
        0 5px 8px rgba(0, 0, 0, 0.07);

    .container-fluid {
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    &__user {
        margin: 0 15px 0 auto;
    }
}
.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: $cb;
    font-weight: 600;

    img {
        margin-left: 20px;
        margin-right: 10px;
        max-height: 30px;
    }

    &:hover {
        color: $cb;
        text-decoration: none;
    }
}
