.main-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    height: 100vh;
    //background: $gray-800;
    background: $cb;
    z-index: 10;
    padding: 20px;
    padding-top: 80px;
    display: flex;
    text-transform: uppercase;
    font-weight: 600;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: none;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        li {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;

            &.logout {
                margin-top: auto;
            }
        }
    }

    a {
        color: #fff;
        display: block;

        &:active,
        &:hover {
            text-decoration: none;
            color: #fff;
        }
    }

    &__logout {
        margin-top: auto;
    }
}

:root {
    --menu-width: 28px;
}
.main-menu-trigger {
    position: relative;
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;

    span {
        display: block;
        width: var(--menu-width);
        height: 3px;
        background: $color-text;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: calc(var(--menu-width) / -2);
        transition: all 0.3s ease;

        &:nth-child(1) {
            margin-top: -10px;
        }
        &:nth-child(3) {
            margin-top: 10px;
        }
    }

    &:hover {
        span {
            background: $cb;

            &:nth-child(1) {
                transform: translateX(-5px);
            }
            &:nth-child(3) {
                transform: translateX(-3px);
            }
        }
    }
}

.main-menu-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;

    span {
        display: block;
        width: var(--menu-width);
        height: 3px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: calc(var(--menu-width) / -2);

        &:nth-child(1) {
            transform: rotate(45deg);
        }
        &:nth-child(2) {
            transform: rotate(-45deg);
        }
    }
}
