body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    background: #f7f9f9;
    color: $color-text;
    overflow-x: hidden;
    min-height: 100vh;

    &.simple-page {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .header {
            display: none;
        }
    }
}

a {
    color: $cb;
}

.page-title {
    margin-bottom: 30px;
    display: block;
    width: 100%;

    h1 {
        margin-bottom: 0;

        .text-small {
            font-size: 18px;
        }
    }

    &__toolbar {
    }
    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__toolbar {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
}
.filters {
    position: relative;
    display: none;

    &__close {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.box {
    background: #fff;
    border: 1px solid $gray-300;
    padding: 30px;
    margin-bottom: 30px;

    &--login {
        min-width: 280px;
        width: 100%;
        max-width: 360px;
        margin: 0 auto;
        box-shadow: 0 0.8px 1.5px rgba(0, 0, 0, 0.02),
            0 2px 3.5px rgba(0, 0, 0, 0.028), 0 3.8px 6.6px rgba(0, 0, 0, 0.035),
            0 6.7px 11.8px rgba(0, 0, 0, 0.042),
            0 12.5px 22.1px rgba(0, 0, 0, 0.05), 0 30px 53px rgba(0, 0, 0, 0.07);
    }
}

.daterangepicker {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
}

.dataTables_wrapper {
    margin-bottom: 30px;
}

.table {
    background: #fff;
    color: $color-text;

    thead {
        th {
            background: $cb;
            color: #fff;
            // background: lighten($cb, 40%);
        }
    }
}

.color-brand {
    color: $cb;
}

hr {
    margin-top: 15px;
    margin-bottom: 15px;
}

.list-group-flush {
    .list-group-item {
        padding-left: 0;
        padding-right: 0;
    }
}

.modal-footer {
    justify-content: flex-start;
}

.bootbox {
    .modal-footer {
        justify-content: flex-end;
    }
}
