.review-details {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    font-size: 18px;
    padding: 15px;
    background: $gray-200;

    li {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        align-items: baseline;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__legend {
        font-weight: 700;
        display: block;
        color: #222;
        font-size: 13px;
        // text-align: right;
    }
    &__value {
    }
}

.step-status {
    border: 1px solid $gray-200;
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
    // font-size: 16px;
    padding: 30px;
    background: $gray-100;

    li:last-child {
        margin-bottom: 0;
    }
}

.step--right-container {
    position: sticky;
    top: 10px;
}
