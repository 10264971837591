body {
    font-weight: normal;
}

h1 {
    font-weight: 300;
    letter-spacing: -2px;
    font-size: 32px;
}

h2 {
    font-size: 28px;
    margin-top: 40px;
    margin-bottom: 20px;
    letter-spacing: -0.5px;
}

h3 {
    margin-bottom: 30px;
    color: #111;
    font-size: 24px;
    position: relative;

    // &:before {
    //     content: "";
    //     display: block;
    //     width: 30px;
    //     height: 6px;
    //     background: $cb;
    //     position: absolute;
    //     right: calc(100% + 15px);
    //     top: calc(50% - 3px);
    // }
}

h5 {
    font-size: 17px;
    font-weight: bold;
    color: $cb;
    margin-bottom: 10px;
}
