.form-label {
    font-weight: 700;
    color: $gray-800;
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
}

.table-search {
    width: 100%;
    max-width: 150px;
    padding-right: 40px;
    // background-image: url()
}

// .form-control:disabled,
// .form-control[readonly] {
//     background: $gray-100;
//     border-color: $gray-200;
// }
