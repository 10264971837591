// Body
// $body-bg: #f8fafc;

// Typography
// $font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e74a3b;
$orange: #f6993f;
$yellow: #f6c23e;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$cb: #3D00CF;
$cb-sec: #0F2A3B;

$color-text: $cb-sec;
$primary: $cb;

$spacer: 2rem;
// $enable-rounded: false;
// $grid-columns: 12;
// $grid-gutter-width: 60px;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 480px,
//   md: 768px,
//   lg: 1024px
// );

$container-max-widths: (
    sm: 100%,
    md: 100%,
    lg: 1600px,
);
