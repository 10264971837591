.stats-panels {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
    }

    > a {
        text-decoration: none;
        color: $color-text;
        display: flex;
        width: 100%;
        height: 100%;
    }
}

.stat-panel {
    border-radius: 0.25rem;
    overflow: hidden;
    width: 100%;
    display: flex;
    background: linear-gradient(to bottom right, #fff, #fff);
    // transition: all 0.2s ease-in-out;
    box-shadow: 0.1px 0.4px 0.7px rgba(0, 0, 0, 0.028), 0.4px 1.3px 2.2px rgba(0, 0, 0, 0.042), 2px 6px 10px rgba(0, 0, 0, 0.07);

    &:hover {
        background: linear-gradient(to bottom right, #fff 50%, #eee);
    }

    $this: &;

    &__icon {
        aspect-ratio: 1;
        flex-shrink: 0;
        background: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 80px;

        @include media-breakpoint-up(md) {
            width: 30%;
        }

        &:before {
            content: '';
            background: url('/img/bg-dashboard.png') no-repeat center;
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            // opacity: 0.8;
            mix-blend-mode: luminosity;

            @include media-breakpoint-up(md) {
                background-size: 130px;
            }
        }

        svg {
            fill: #fff;
            width: 50%;
            z-index: 1;
        }
    }

    &__text {
        line-height: 1.4;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
    }

    &__title {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
    }

    &__value {
        font-size: 32px;
        font-weight: 500;
        color: #333;
    }

    &--1 {
        #{$this}__icon {
            background: $cb;
        }
    }
    &--2 {
        #{$this}__icon {
            background: $orange;
        }
    }
    &--3 {
        #{$this}__icon {
            background: $blue;
        }
    }
    &--4 {
        #{$this}__icon {
            background: $red;
        }
    }
}
